<template>
  <div>
    <div class="layer_comm">
      <PopupToast :isPopup="coupon.isPopup"><p v-html="coupon.cuponPopupMessage"></p></PopupToast>
      <div class="dimmed"></div>
      <div class="inner_wrapper">
        <div class="inner_layer">
          <div class="coupon_title">즉시 사용 가능한 추가 할인 쿠폰</div>
          <div class="coupon__container" id="togo1">
            <button
              v-for="(item, index) in coupon.couponData?.coupon?.data?.coupons.sort((a, b) => b.idx - a.idx)"
              :key="index"
              @click="!coupon.hasCouponIdxArray(item.idx) && !item.hasCoupon && coupon.postCouponDownload(item.idx)"
              :class="[
                { 'coupon coupon200000': item.dc_money === 200000 },
                { 'coupon coupon100000': item.dc_money === 100000 },
                { 'coupon coupon70000': item.dc_money === 70000 },
                { 'coupon coupon50000': item.dc_money === 50000 },
                { 'coupon coupon30000': item.dc_money === 30000 },
                { 'coupon coupon15000': item.dc_money === 15000 },
                { 'is-download': item.hasCoupon || coupon.hasCouponIdxArray(item.idx) },
              ]"
              :data-tracking="'CouponDownload_' + item.idx"
            ></button>
            <button
              v-if="coupon.hasCouponIdx.length < 4"
              class="coupon_button"
              @click="coupon.postCouponDownloadAll()"
              data-tracking="2023Festival_CouponDownload_all"
            >
              쿠폰 한 번에 받기
            </button>
            <button
              v-if="coupon.hasCouponIdx.length >= 5"
              class="coupon_button_enabled"
              @click="coupon.postCouponDownloadAll()"
              disabled
            >
              다운로드 완료
            </button>
          </div>
        </div>
        <div class="coupon_notice">
          <div class="notice_title">
            <p>쿠폰 유의 사항</p>
          </div>
          <div class="notice_desc">
            <p>• 쿠폰 다운로드 후 사용 가능</p>
            <p>• 정기 결제 수강권 구매 시 사용 불가</p>
          </div>
        </div>
        <div class="extra_wrapper">
          <button class="extra_button" @click="emit('toggleModal')">
            <img class="close_icon" src="//res.tutoring.co.kr/res/images/img/plans/ic_cp_modal_close.png" alt="close" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, reactive, onMounted } from 'vue';
import api from '@/common/api';
import PopupToast from '@/components/PopupToast.vue';

const props = defineProps({
  couponData: Object,
});

const coupon = reactive({
  isCouponClicked: false,
  isPopup: false,
  cuponPopupMessage: '쿠폰 다운로드 완료!',
  couponData: { plans: [{ cp_idx: 0 }] },
  hasCouponIdx: [],
  allCouponIdx: [],
  isDownload: true,
  clickPopupButton: () => {
    coupon.isPopup = true;
    setTimeout(() => {
      coupon.isPopup = false;
    }, 2000);
  },
  postCouponDownload: async (dcIdx) => {
    const userKey = localStorage.getItem('key');
    if (!userKey) {
      window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
    } else {
      await api
        .post(`/app/users/coupon/discount/${dcIdx}`, {})
        .then((response) => {
          if (response.result === 2000) {
            coupon.cuponPopupMessage = '쿠폰 다운로드 완료!';
            coupon.hasCouponIdx.push(dcIdx);
            coupon.clickPopupButton();
          } else if (response.result === 1000) {
            coupon.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
            coupon.clickPopupButton();
          }
        })
        .catch((response) => {
          console.log(response);
        });
    }
  },
  postCouponDownloadAll: async () => {
    const userKey = localStorage.getItem('key');
    if (!userKey) {
      window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
    } else {
      if (coupon.hasCouponIdx.length >= 4) {
        coupon.isDownload = !coupon.isDownload;
        return;
      }
      await api
        .post(`/app/users/coupon/download/all`, { couponIdx: coupon.allCouponIdx.toString() })
        .then((response) => {
          if (response.result === 2000) {
            coupon.cuponPopupMessage = '쿠폰 다운로드 완료!';
            coupon.hasCouponIdx.push(...coupon.allCouponIdx);
            coupon.clickPopupButton();
          } else if (response.result === 1000) {
            coupon.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
            coupon.clickPopupButton();
          }
        })
        .catch((response) => {
          console.log(response);
        });
    }
  },
  hasCouponIdxArray: (idx) => coupon.hasCouponIdx.includes(idx),
  checkCoupon: () => {
    coupon.couponData.coupon.data.coupons.forEach((item) => {
      coupon.allCouponIdx.push(item.idx);
      if (item.hasCoupon) {
        coupon.hasCouponIdx.push(item.idx);
      }
    });
  },
});

onMounted(() => {
  coupon.couponData = props.couponData;
  coupon.checkCoupon();
});
const emit = defineEmits(['toggleModal']);
</script>
<style scoped lang="scss" src="../../../../assets/ChatPass.scss"></style>
