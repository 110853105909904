<template>
  <div class="coupon">
    <div class="coupon-download-contaienr">
      <button class="coupon-download-box" @click="coupon.showCouponModal" data-tracking="CouponDownloadComponent">
        <div class="box">
          <div class="left-img"></div>
          <div class="text">
            <p class="top">튜터링 페스티벌</p>
            <p class="title">최대 72% 할인</p>
          </div>
        </div>
        <div class="download-text">쿠폰받기</div>
      </button>
      <!-- <p class="desc">자세한 쿠폰 사용 안내는 유의사항을 참고해 주세요.</p> -->
    </div>
  </div>
  <!-- 쿠폰 모달 -->
  <CouponModal v-if="coupon.isCouponClicked" :couponData="coupon.couponData" @toggleModal="coupon.showCouponModal" />
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import api from '@/common/api';
import CouponModal from '../ChatPass/component/CouponModal.vue';

const coupon = reactive({
  isCouponClicked: false,
  couponData: {},
  showCouponModal: () => {
    coupon.getCouponData();
    coupon.isCouponClicked = !coupon.isCouponClicked;
    if (coupon.isCouponClicked) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.pageYOffset}`;
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.position = '';
      document.body.style.width = '';
    }
  },
  getCouponData: async () => {
    coupon.couponData = await api
      .get('/app/etc/promotion/coupons?promotion=festival')
      .then((response) => response.data);
  },
});

onMounted(() => {
  coupon.getCouponData();
});
</script>

<style lang="scss" scoped>
.coupon {
  width: 100%;
  max-width: 1000px;
}

@media (max-width: 767px) {
  .coupon-download-contaienr .coupon-download-box {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .coupon-download-contaienr .coupon-download-box {
    width: 530px;
  }
}
.coupon-download-contaienr {
  /* background-color: #fff; */
  /* background: red; */
  /* padding: 20px 20px 50px 20px; */
  .coupon-download-box {
    padding: 26px 24px;
    width: 100%;
    border-radius: 16px;
    border-top: 2px solid var(--opacity-black-50, rgba(18, 18, 20, 0.04));
    border-right: var(--spacing-xxxs, 2px) solid var(--opacity-black-50, rgba(18, 18, 20, 0.04));
    border-bottom: var(--spacing-xxxs, 2px) solid var(--opacity-black-50, rgba(18, 18, 20, 0.04));
    border-left: var(--spacing-xxxs, 2px) solid var(--opacity-black-50, rgba(18, 18, 20, 0.04));
    background: var(--opacity-white, #fff);
    /* padding: 20px 20px 50px 20px; */

    display: flex;
    align-items: center;

    justify-content: space-between;

    cursor: pointer;
    .box {
      display: flex;
      align-items: center;
      .left-img {
        width: 50px;
        height: 41px;
        background-image: url('//res.tutoring.co.kr/res/images/img/plans/ic_plan_coupon.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50px 41px;
        margin: 0;
      }
    }
    .text {
      margin-left: 25px;
      .top {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.5px;
        color: #afabb7;
        margin-bottom: 5px;
        text-align: left;
      }
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -1px;
        color: #121214;
      }
    }
    .download-text {
      color: var(--primary-800, #4b03af);
      text-align: center;
      /* H4 */
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }
    .download-img {
      width: 46px;
      height: 46px;
      background-image: url('//res.tutoring.co.kr/res/images/img/plans/btn_down.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 46px 46px;
      /* margin-right: 25px; */
    }
    .download-done {
      width: 96px;
      height: 100%;
      background: #efe6fb;
      border-radius: 0 20px 20px 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: rgba(93, 4, 217, 0.5);
      /* padding: 32px 35px; */
    }
  }
  .desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #afabb7;
  }
}
</style>
