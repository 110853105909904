import { render } from "./CouponModal.vue?vue&type=template&id=7c61501c&scoped=true"
import script from "./CouponModal.vue?vue&type=script&setup=true&lang=js"
export * from "./CouponModal.vue?vue&type=script&setup=true&lang=js"

import "../../../../assets/ChatPass.scss?vue&type=style&index=0&id=7c61501c&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-7c61501c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7c61501c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7c61501c', script)) {
    api.reload('7c61501c', script)
  }
  
  module.hot.accept("./CouponModal.vue?vue&type=template&id=7c61501c&scoped=true", () => {
    api.rerender('7c61501c', render)
  })

}

script.__file = "src/page/promotion/ChatPass/component/CouponModal.vue"

export default script